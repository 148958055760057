//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.container
{
    margin-top: 0;

    .contactContainer
    {
        p
        {
            overflow: visible;
        }

        .contactButtonContainer
        {
            display:         flex;
            flex-direction:  column;
            justify-content: space-between;
            height:          100%;
            gap:             10px;

            > button
            {
                display: flex;
                flex:    1;
            }
        }
    }
}
