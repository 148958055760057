//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$navigationBarPadding: 12.60px;

.sideNavigationBar
{
    display:          flex;
    flex-direction:   column;
    align-items:      center;
    position:         sticky;
    z-index:          $zIndexSideNavigationBar;
    bottom:           0;
    left:             0;
    top:              0;
    height:           100vh;
    width:            232px;
    box-shadow:       6.30px 1.40px 4.20px 0 $black16;
    background-color: $scarlet;
    border-radius:    0 21px 21px 0;
    padding:          10.50px 14px 24.50px;
    color:            $white;
    font-size:        $fontSizeExtraExtraLarge;

    .upperLeftLogoContainer
    {
        margin: 50px 0;

        .logo
        {
            width:  90%;
            height: auto;
        }
    }

    .sideNavigationBarEntries
    {
        display:         flex;
        flex-direction:  column;
        justify-content: space-between;
        flex:            1;
        width:           100%;

        .sideNavigationBarEntry
        {
            display:         flex;
            flex-direction:  row;
            justify-content: flex-start;
            align-items:     center;
            height:          49px;
            border-radius:   11.90px;
            margin-bottom:   14px;
            padding:         $navigationBarPadding;
            text-decoration: none;
            color:           $white;

            .sideNavigationBarIcon
            {
                width:        30px;
                margin-right: 14px;
                text-align:   left;

                svg
                {
                    @include svgColor($white, true);

                    width: 21px;
                }
            }

            &.sideNavigationBarEntryActive
            {
                background-color: $white40;
            }

            &.logout
            {
                margin-top: auto;
            }
        }
    }
}

.bottomNavigationBar
{
    display:          flex;
    flex-direction:   row;
    justify-content:  space-around;
    align-items:      center;
    position:         fixed;
    bottom:           0;
    left:             0;
    right:            0;
    width:            100vw;
    height:           70px;
    padding:          17.50px 0;
    background-color: $white2;
    z-index:          $zIndexBottomNavigationBar;

    .bottomNavigationBarEntries
    {
        display:         flex;
        flex-direction:  row;
        justify-content: space-around;
        height:          100%;
        width:           fit-content;

        .bottomNavigationBarEntry
        {
            height: 100%;
            width:  45.50px;
            margin: 0 19.60px;

            &:first-child
            {
                margin-left: 0;
            }

            &:last-child
            {
                margin-right: 0;
            }

            .bottomNavigationBarIcon
            {
                height: 100%;

                svg
                {
                    height: 100%;

                    @include svgColor($charcoalGrey, true);
                }

            }

            &.bottomNavigationBarEntryActive
            {
                svg
                {
                    @include svgColor($primaryColor, true);
                }
            }
        }
    }
}

.navigationBarExtraEntries
{
    margin-top: auto;
}

