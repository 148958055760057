//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.splide
{
    margin-top:      auto;
    padding:         0 56px;
    justify-content: center;

    .arrow
    {
        $arrowSize:       16.80px;

        width:            $arrowSize;
        height:           $arrowSize;
        display:          flex;
        align-items:      center;
        justify-content:  center;
        border-radius:    0;
        opacity:          1;
        background-color: transparent;

        svg
        {
            width:  $arrowSize;
            height: $arrowSize;

            @include svgColorFill($scarlet, true);
        }
    }
}

:global
{
    .splide__arrow--prev
    {
        left: 0;
    }

    .splide__arrow--next
    {
        right: 0;
    }
}
