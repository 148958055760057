//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.progressBarContainer
{
    $clickableOverlayHeight:           16px;
    $clickableOverlayVerticalPosition: calc(#{$clickableOverlayHeight / 2 * -1});

    position:                          absolute;
    bottom:                            56px;
    width:                             100%;
    height:                            3.50px;
    background-color:                  $pinkishGey;
    cursor:                            pointer;

    .progressBar
    {
        height:           100%;
        background-color: $scarlet;
    }

    .progressBarOverlay
    {
        position: absolute;
        height:   $clickableOverlayHeight;
        inset:    $clickableOverlayVerticalPosition 0 $clickableOverlayVerticalPosition 0;
        cursor:   pointer;
    }
}
