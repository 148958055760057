//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.loadingOverlay
{
    display:    none;
    top:        0;
    left:       0;
    background: $white80;
    z-index:    $zIndexLoadingOverlay;
    position:   fixed;
    width:      100%;
    height:     100%;
    overflow:   hidden;

    .loadingOverlayPulse
    {
        margin: auto;

        .loadingOverlayText
        {
            position:    absolute;
            margin-top:  84px;
            margin-left: -70px;
            width:       140px;
            height:      140px;
            text-align:  center;
        }
    }
}

@keyframes pulseAnimation
{
    0%
    {
        box-shadow: 0 0 0 0 $red80;
    }

    100%
    {
        box-shadow: 0 0 0 140px transparent;
    }
}

.loadingOverlayVisible
{
    display: flex;

    .loadingOverlayPulse
    {
        span
        {
            position:      absolute;
            margin-top:    -70px;
            margin-left:   -70px;
            width:         140px;
            height:        140px;
            border-radius: 50%;
            background:    $red;
            animation:     pulseAnimation 1.3s infinite;

            &:nth-child(2)
            {
                animation-delay: 0.3s;
            }
        }
    }
}
