//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.videoOverlay
{
    position:         fixed;
    width:            100vw;
    height:           100vh;
    left:             0;
    top:              0;
    z-index:          $zIndexVideoOverlay;
    backdrop-filter:  blur(3.50px);
    background-color: $charcoalGrey10;
    padding:          31.50px 56px;
}

.videoBackground
{
    position:   absolute;
    width:      100%;
    height:     100%;
    object-fit: cover;
}

.contentOverlay
{
    position:         relative;
    width:            100%;
    height:           100%;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    background-color: $charcoalGrey;
}

.headline
{
    position:    absolute;
    font-size:   $fontSizeGigantic;
    top:         0;
    left:        0;
    margin:      14px 21px;
    color:       $scarlet;
    user-select: none;
}

.closeButton,
.playPauseButton
{
    cursor: pointer;

    &.closeButton
    {
        $closeButtonSize: 26.60px;

        position:         absolute;
        top:              0;
        right:            0;
        margin:           17.50px;
        width:            $closeButtonSize;
        height:           $closeButtonSize;

        svg
        {
            width:  $closeButtonSize;
            height: $closeButtonSize;

            @include svgColorFill($scarlet)
        }
    }

    &.playPauseButton
    {
        $playButtonSize: 26.60px;

        position:        absolute;
        left:            0;
        bottom:          0;
        margin:          10.50px 31.50px;
        width:           $playButtonSize;
        height:          $playButtonSize;

        svg
        {
            width:  $playButtonSize;
            height: $playButtonSize;

            @include svgColorFill($scarlet)
        }
    }
}

.progressBarContainer
{
    position:         absolute;
    bottom:           56px;
    width:            100%;
    height:           3.50px;
    background-color: $pinkishGey;
}

.progressBar
{
    height:           100%;
    background-color: $scarlet;
}
