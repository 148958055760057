//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.screenTooSmallOverlay
{
    position:       fixed;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
    flex-direction: column;
    z-index:        $zIndexScreenTooSmallOverlay;
    background:     $white;
    display:        flex;
    min-width:      224px;

    @media only screen and (width >= 1024px)
    {
        display: none;
    }
}

.screenTooSmallOverlayContent
{
    flex:            1;
    background:      $white;
    display:         flex;
    align-items:     center;
    justify-content: center;
    text-align:      center;
    flex-direction:  column;
    color:           $black;
    padding:         35px;

    .backgroundLogo
    {
        max-width:     230px;
        margin-bottom: 20px;
    }

    h2
    {
        white-space: pre-line;
        max-width:   360px;
    }

    p
    {
        margin-top: 10px;
        font-size:  $fontSizeLarge;
    }

    .storeLogos
    {
        margin-top: 30px;

        a > *
        {
            width:  150px;
            height: auto;

            &:first-child
            {
                margin-right: 10px;
            }
        }
    }
}
