//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

/* stylelint-disable no-descending-specificity */

$disabledColor: $warmGray;
$iconSize:      16.80px;

.button
{
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      center;
    justify-content:  center;
    width:            100%;
    height:           39px;
    max-width:        100%;
    color:            $white;
    border:           0.70px solid $primaryColor;
    padding:          8.40px 35px;
    border-radius:    10.50px;
    background-color: $primaryColor;
    cursor:           pointer;
    box-shadow:       3.50px 3.50px 4.20px 0 $black16;
    transition:       color .2s linear,
                      border-color .2s linear,
                      background-color .2s linear;

    .iconContainer
    {
        width: $iconSize;

        &.iconContainerLeft
        {
            padding-right: 8.40px;
        }

        &.iconContainerRight
        {
            padding-left: 8.40px;
        }

        > span
        {
            width:  $iconSize;
            height: $iconSize;

            svg
            {
                @include svgColor($white, true);

                > *
                {
                    transition: fill .2s linear,
                                stroke .2s linear;
                }
            }
        }
    }

    &.iconOnly
    {
        .iconContainer
        {
            padding: 0 !important;
        }
    }

    &.iconLeft,
    &.iconRight
    {
        padding-left: 17.50px;

        &.iconRight
        {
            padding-right: 17.50px;
        }

        &.iconLeft
        {
            padding-left: 17.50px;

            p
            {
                margin-left: 10.50px;
            }
        }

        p
        {
            margin-right: auto;
        }
    }

    &.large
    {
        $largePadding:  14px;

        padding-top:    $largePadding;
        padding-bottom: $largePadding;
    }

    &[disabled]
    {
        cursor:           auto;
        color:            $white !important;
        border-color:     $disabledColor !important;
        background-color: $disabledColor !important;
        pointer-events:   none;

        &.outline,
        &.outlineIcon
        {
            color:            $disabledColor !important;
            background-color: transparent !important;

            .iconContainer
            {
                svg
                {
                    @include svgColor($disabledColor, true);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($white, true);
            }

            &::before
            {
                border-color: $disabledColor !important;
            }
        }
    }

    p
    {
        font-size:   $fontSizeExtraLarge;
        line-height: 1.2;
    }

    &:hover
    {
        color:            $black;
        border-color:     $warmWhite;
        background-color: $warmWhite;
        box-shadow:       3.50px 3.50px 4.20px 0 $black16;

        .iconContainer
        {
            svg
            {
                @include svgColor($primaryColor, true);
            }
        }
    }

    &.outline
    {
        color:            $primaryColor;
        background-color: transparent;

        &:hover
        {
            color:            $white;
            background-color: $primaryColor;

            .iconContainer
            {
                svg
                {
                    @include svgColor($white);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($primaryColor);
            }
        }
    }

    &.outlineIcon
    {
        border:           none;
        gap:              10.50px;
        color:            $primaryColor;
        background-color: transparent;

        p
        {
            font-size:   $fontSizeExtraLarge;
            line-height: 1.2;
        }

        &:hover
        {
            color:            $primaryColor;
            background-color: transparent;

            .iconContainer
            {
                &::before
                {
                    background-color: $primaryColor;
                }

                svg
                {
                    @include svgColor($white);
                }
            }
        }

        .iconContainer
        {
            position: relative;

            &.iconContainerRight
            {
                &::before
                {
                    left: 1.40px;
                }
            }

            &::before
            {
                content:       '';
                position:      absolute;
                top:           -5.60px;
                left:          -7.70px;
                height:        calc(100% + 9.80px);
                aspect-ratio:  1/1;
                border-radius: 4.20px;
                border:        0.70px solid $primaryColor;
                transition:    background-color .2s linear;
            }

            svg
            {
                @include svgColor($primaryColor);
            }
        }
    }

    &.white
    {
        color:            $black;
        border-color:     $lightGray;
        background-color: $lightGray;

        &.outline
        {
            color:            $lightGray;
            background-color: transparent;

            &:hover
            {
                border-color:     $primaryColor;
                background-color: $primaryColor;
            }

            .iconContainer
            {
                svg
                {
                    @include svgColor($lightGray);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($primaryColor, true);
            }
        }

        &:hover
        {
            color:            $lightGray;
            border-color:     $primaryColor;
            background-color: $primaryColor;

            .iconContainer
            {
                svg
                {
                    @include svgColor($lightGray, true);
                }
            }
        }
    }

    &.grey
    {
        color:            $black;
        border-color:     $warmWhite;
        background-color: $warmWhite;

        &.outline
        {
            color:            $warmWhite;
            background-color: transparent;

            &:hover
            {
                border-color:     $primaryColor;
                background-color: $primaryColor;
            }

            .iconContainer
            {
                svg
                {
                    @include svgColor($warmWhite);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($primaryColor, true);
            }
        }

        &:hover
        {
            color:            $warmWhite;
            border-color:     $primaryColor;
            background-color: $primaryColor;

            .iconContainer
            {
                svg
                {
                    @include svgColor($warmWhite, true);
                }
            }
        }
    }

    &.link
    {
        color:       $warmGray;
        border:      0;
        padding:     0;
        font-weight: $fontWeightRegular;
        background:  none;
    }

    &.pressed
    {
        transform: scale(0.95);
    }
}
