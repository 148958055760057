//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.clipspotsContainerList
{
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    gap:             21px;
    margin:          42px 0;

    &::after
    {
        content: "";
        flex:    auto;
    }
}
