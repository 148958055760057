//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.clipspotsContainer
{
    display:          flex;
    flex-direction:   column;
    position:         relative;
    padding:          30px 25px;
    border-radius:    21px;
    box-shadow:       3.5px 3.5px 4.2px 0 $black16;
    background-color: $lightGray;
    width:            305px;
    height:           265px;
    line-height:      1.2;
    font-size:        $fontSizeExtraExtraLarge;
    margin:           7px 0;

    .headline
    {
        font-size:     $fontSizeGigantic;
        color:         $primaryColor;
        text-align:    left;
        overflow:      hidden;
        white-space:   nowrap;
        text-overflow: ellipsis;
    }

    .subtitle
    {
        font-size:  $fontSizeExtraLarge;
        color:      $charcoalGrey;
        text-align: left;
    }

    .image
    {
        $borderRadius: 14px;
        $border:       0.5px;

        position:      relative;
        height:        145px;
        width:         100%;
        border-radius: $borderRadius;
        border:        solid $border $gray;

        .iconImage
        {
            $iconSize: 52px;

            position:  absolute;
            inset:     0 0 0 0;
            margin:    auto;
            width:     $iconSize;
            height:    $iconSize;

            svg
            {
                width:  $iconSize;
                height: $iconSize;

                @include svgColorFill($warmGray);
            }
        }

        img
        {
            border-radius: calc(#{$borderRadius} - #{$border});
            object-fit:    fill;
            width:         100%;
            height:        100%;
        }
    }

    &.small
    {
        padding:       21px 11.9px;
        width:         158px;
        height:        137px;
        border-radius: 10px;

        .headline
        {
            font-size:     $fontSizeExtraLarge;
            margin-bottom: 3.5px;
        }

        .image
        {
            height: 73px;
        }
    }

    &.medium
    {
        width:  260px;
        height: auto;

        .headline
        {
            font-size:     $fontSizeExtraExtraExtraLarge;
            margin-bottom: 3.5px;
        }
    }

    &.wide
    {
        width: 509px;
    }

    &.full
    {
        width:  100%;
        height: auto;
    }
}

.button
{
    margin-top: 26px;

    > button
    {
        height: 50px;
    }
}

.clickable
{
    cursor: pointer;
}
