//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.videoDetailContainer
{
    flex:            1;
    display:         flex;
    flex-direction:  column;
    justify-content: flex-start;

    .videoClipspotsContainer
    {
        display:        flex;
        flex-direction: row;
        height:         260px;

        > div
        {
            height:        auto;
            margin-top:    0;
            margin-bottom: 0;
        }
    }

    .additionalButtonContainer
    {
        display:        flex;
        gap:            20px;
        flex-direction: column;
        width:          308px;
        margin:         7px 0 7px 14px;

        > *
        {
            height: 50px;
        }
    }
}
