//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$inputHeight: 35px;

.textInput
{
    width:            100%;
    border:           none;
    height:           $inputHeight;
    min-height:       $inputHeight;
    outline:          none;
    font-size:        $fontSizeExtraLarge;
    font-family:      $fontSourceGeneralBody;
    box-sizing:       border-box;
    padding:          8.40px 11.20px;
    border-radius:    10.50px;
    background-color: $warmWhite;
    margin-bottom:    8.40px;

    &.textInputDisabled
    {
        color:  $gray;
        cursor: not-allowed;
    }

    &.textInputMultiLine
    {
        height: 100%;
        resize: none;
    }

    &.textInputSingleLine
    {

    }

    &.textInputWithPostIcon
    {
        padding-right: $inputHeight;
    }

    &::placeholder
    {
        color: $warmGray;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
    {
        appearance: none;
        margin:     0;
    }

    &[type="number"]
    {
        appearance: textfield;
    }

    &:focus
    {
        border-color: $gray;
    }
}
