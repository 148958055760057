//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editableText
{
    width:            260.40px;
    height:           147px;
    border-radius:    10.50px;
    background-color: #e8e8e8;
    padding:          7px;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    cursor:           pointer;
    font-size:        $fontSizeExtraExtraLarge;
    line-height:      1.17;
    color:            $charcoalGrey;
}

.text,
.input
{
    overflow-x: hidden;
    overflow-y: auto;

    &.input
    {
        background:  transparent;
        border:      none;
        outline:     none;
        width:       100%;
        height:      100%;
        color:       inherit;
        font-size:   $fontSizeExtraExtraLarge;
        font-family: $fontSourceGeneralBody;
        line-height: 1.17;
        display:     flex;
        caret-color: $primaryColor;
        resize:      none;
    }

    &.text
    {
        white-space: pre-wrap;
        height:      100%;
        width:       100%;
    }
}
