//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$fontSizeSmall:                9px;
$fontSizeMedium:               11px;
$fontSizeLarge:                13px;
$fontSizeExtraLarge:           14px;
$fontSizeExtraExtraLarge:      17px;
$fontSizeExtraExtraExtraLarge: 18px;
$fontSizeHuge:                 22px;
$fontSizeGigantic:             25px;
$fontSizeSuperGigantic:        39px;
