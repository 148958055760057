//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headlineSubtitle
{
    .headline
    {
        font-size:     $fontSizeSuperGigantic;
        font-weight:   normal;
        color:         $scarlet;
        margin-bottom: 5.60px;
    }

    .subtitle
    {
        font-size:   $fontSizeExtraLarge;
        font-weight: normal;
        color:       $charcoalGrey;
    }

    &.pageSection
    {
        .headline
        {
            font-size: $fontSizeGigantic;
        }

        .subtitle
        {
            font-size: $fontSizeExtraExtraLarge;
        }
    }
}
