//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.pageContainer
{
    min-height: 100vh;

    .page
    {
        display:        flex;
        flex-direction: row;
        width:          100%;
        min-height:     100vh;

        .childrenContainer
        {
            display:        flex;
            flex-direction: column;
            flex:           1;
            padding:        45.50px 45.50px 84px;
            overflow:       auto;
            z-index:        $zIndexChildrenContainer;
        }
    }

    .backgroundLogo,
    .lowerLeftLogo,
    .upperLeftLogo
    {
        z-index:  $zIndexPageBackgroundLogos;
        position: fixed;
        overflow: hidden;

        &.upperLeftLogo
        {
            width: 189px;
            left:  42px;
            top:   28px;
        }

        &.backgroundLogo
        {
            width: 330px;
            top:   42px;
            right: -49px;
        }

        &.lowerLeftLogo
        {
            width:  560px;
            left:   -112px;
            bottom: -280px;
        }
    }
}
