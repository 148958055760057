//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$errorColor:   $cherry;
$successColor: $cherry;

.alertBox
{
    width:  294px;
    height: fit-content;
}

.alertBoxCloseButtonWrapper
{
    $iconSize: 17.50px;

    position:  absolute;
    cursor:    pointer;
    top:       14px;
    right:     14px;
    width:     $iconSize;
    height:    $iconSize;
}

.alertBoxIcon
{
    $size:           14px;

    width:           $size;
    height:          $size;
    border-radius:   $size * 0.5;
    position:        absolute;
    top:             50%;
    left:            12.60px;
    margin:          (-$size * 0.5) 0 0 0;
    display:         flex;
    justify-content: center;
    align-items:     center;
}

/* stylelint-disable no-descending-specificity */
// The rule is disabled because its not solvable no matter which order they are in.
.alertBoxSuccess
{
    svg
    {
        @include svgColor($successColor);
    }

    .alertBoxIcon
    {
        background: $successColor;

        svg
        {
            @include svgColor($white);
        }
    }
}

.alertBoxError
{
    svg
    {
        @include svgColor($errorColor);
    }

    .alertBoxIcon
    {
        background: $errorColor;

        svg
        {
            @include svgColor($white);

            margin: 0.70px 0 0 0.70px;
        }
    }
}

/* stylelint-enable no-descending-specificity */
