//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.footer
{
    display:         flex;
    flex-direction:  row;
    justify-content: center;
    align-items:     center;
    position:        fixed;
    bottom:          84px;
    left:            0;
    right:           0;
    width:           100vw;
    height:          17.50px;
    z-index:         $zIndexBottomNavigationBar;

    .footerEntry
    {
        font-size:       $fontSizeExtraLarge;
        text-decoration: none;
        color:           $charcoalGrey;

        &:not(:last-child)
        {
            &::after
            {
                content:      "|";
                margin-left:  7px;
                margin-right: 7px;
            }
        }
    }
}
