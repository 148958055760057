//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.icon
{
    display:         inline-block;
    background-size: contain;
    width:           100%;
    height:          auto;
    position:        relative;
    line-height:     0;

    svg
    {
        *
        {
            @include svgColor($white);

            transition: fill 0.1s linear;
        }
    }
}
